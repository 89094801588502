<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="big" type="primary" @click="submitForm">
        Сохранить
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="settings"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Минимальное кол-во суток для аренды">
        <el-input-number
          v-model="settings.minOrderDays"
          :min="1"
          :max="300"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Дней резерва до заказа">
        <el-input-number
          v-model="settings.orderStartDelayDays"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Дней резерва после заказа">
        <el-input-number
          v-model="settings.orderEndDelayDays"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Общие условия аренды">
        <TextEditor :value.sync="settings.rentTerms" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'

import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  components: { TextEditor },
  mixins: [notifications],
  data() {
    return {
      settings: {
        minOrderDays: 0,
        orderStartDelayDays: 0,
        orderEndDelayDays: 0,
        rentTerms: '',
      },
    }
  },
  async created() {
    await this.getSettings()
  },
  methods: {
    async getSettings() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SettingsActions.getList()

      loading.close()

      if (error) {
        error !== 'Error: mongo: no documents in result' &&
          this.showNotification('Ошибка получения настроек', 'error')
        return
      }

      this.settings = value
    },
    async submitForm() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.SettingsActions.update(this.settings)

      loading.close()

      if (error) {
        this.showNotification('Ошибка сохранения настроек', 'error')
        return
      }

      this.showNotification('Настройки успешно сохранены', 'success')
      this.$router.push(ADDEVENT_ADMIN_ROUTES.INDEX)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem 2rem 1rem 1rem;
  .wrapper {
    @include stickyWrapper;
  }

  .form {
    & > div > label {
      text-align: left;
    }
  }
}
</style>
